<template>
  <div>
    <div class="mb-8 ">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
      <div v-if="setor">
        <v-chip
          class="mr-1"
          small
        >
          Setor: {{ setor }}
        </v-chip>
      </div>
    </div>
    <v-form ref="form" class="row">
      <input-select
        v-model="form.campoRelacional"
        :options="opcoes.campo"
        @change="handleCampoChange"
        class="col-12 col-md-4"
        :label="$t('modulos.kanban_configuracao.formulario.campo')"
        :placeholder="$t('modulos.kanban_configuracao.formulario.campo')"
        obrigatorio
      />

      <input-select
        v-model="form.operadorRelacional"
        :options="opcoes.operador"
        class="col-12 col-md-4"
        :label="$t('modulos.kanban_configuracao.formulario.operador')"
        :placeholder="$t('modulos.kanban_configuracao.formulario.operador')"
        obrigatorio
      />

      <input-select
        v-if="form.campoRelacional === 'Situacao'"
        v-model="form.situacao"
        :options="opcoes.situacao"
        class="col-12 col-md-4"
        :label="$t('modulos.kanban_configuracao.formulario.valor')"
        :placeholder="$t('modulos.kanban_configuracao.formulario.valor')"
        value-key="value"
        obrigatorio
      />

      <input-select
        v-else-if="form.campoRelacional === 'Prioridade'"
        v-model="form.prioridadeId"
        :options="opcoes.prioridade"
        class="col-12 col-md-4"
        :label="$t('modulos.kanban_configuracao.formulario.valor')"
        :placeholder="$t('modulos.kanban_configuracao.formulario.valor')"
        obrigatorio
      />

      <input-text
        v-else
        v-model="form.numeroDiasParaDataEntrega"
        class="col-12 col-md-4"
        :label="$t('modulos.kanban_configuracao.formulario.valor')"
        :placeholder="$t('modulos.kanban_configuracao.formulario.valor')"
        :max="5"
        type="number"
        obrigatorio
      />

      <div class="col-12 col-md-4 d-flex align-center">
        <input-text
          v-model="corHexaCartaoComHashtag"
          class="flex-fill"
          :label="$t('modulos.kanban_configuracao.formulario.cor_cartao')"
          :placeholder="$t('modulos.kanban_configuracao.formulario.cor_cartao')"
          obrigatorio
          trim
        />
        <color-picker
          v-model="form.corHexaFundoCartao"
          input-id="cp-hex"
          format="hex"
          class="mt-8"
        />
      </div>

      <div class="col-12 col-md-4 d-flex align-center">
        <input-text
          v-model="corFonteComHashtag"
          class="flex-fill"
          :label="$t('modulos.kanban_configuracao.formulario.cor_fonte_cartao')"
          :placeholder="$t('modulos.kanban_configuracao.formulario.cor_fonte_cartao')"
          obrigatorio
          trim
        />
        <color-picker
          v-model="form.corHexaFonteCartao"
          input-id="cp-hex"
          format="hex"
          class="mt-8"
        />
      </div>

    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import KanbanConfiguracaoSetorGeralService from '@common/services/cadastros/KanbanConfiguracaoSetorGeralService';
import { KanbanConfiguracaoSetorGeralModel } from '@common/models/cadastros/KanbanConfiguracaoSetorGeralModel';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import SetorService from '@common/services/cadastros/SetorService';
import { PrioridadeService } from '@/common/services/cadastros';
import helpers from '@common/utils/helpers';
import { mapActions } from 'vuex';

export default {
  props: {
    ['id']: { type: [String, Number], default: null, },
  },

  data() {
    return {
      valido: false,
      setorId: null,
      setor: null,
      form: new KanbanConfiguracaoSetorGeralModel({}),
      opcoes: {
        campo: [
          { text: this.$t('modulos.kanban_configuracao.formulario.situacao'), value: 'Situacao' },
          { text: this.$t('modulos.kanban_configuracao.formulario.prioridade'), value: 'Prioridade' },
          { text: this.$t('modulos.kanban_configuracao.formulario.data_entrega'), value: 'NumeroDiasDataEntrega'}
        ],
        operador: [],
        prioridade: [],
        situacao: []
      },
    };
  },

  computed: {
    tituloFormulario() {
      if (this.id) return this.$t('modulos.kanban_configuracao.titulos.editar_cartao');
      return this.$t('modulos.kanban_configuracao.titulos.novo_cartao');
    },
    corHexaCartaoComHashtag: {
      get() {
        if (this.form.corHexaFundoCartao.includes('#'))
          return this.form.corHexaFundoCartao;
        return `#${this.form.corHexaFundoCartao}`;
      },
      set(value) {
        this.form.corHexaFundoCartao = value.replace(/^#/, '');
      },
    },
    corFonteComHashtag: {
      get() {
        if (this.form.corHexaFonteCartao.includes('#'))
          return this.form.corHexaFonteCartao;
        return `#${this.form.corHexaFonteCartao}`;
      },
      set(value) {
        this.form.corHexaFonteCartao = value.replace(/^#/, '');
      },
    },
  },

  watch: {
    'form.campoRelacional': function() {
      this.limparCampos();
    }
  },

  mounted() {
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);

    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Kanban', 'Editar');
      this.buscar(this.id);
    }

    helpers.redirecionarSemPermissao(this, 'Instrumento', 'Inserir');
    if (!this.id) {
      this.form.setorId = this.$route.params.id;
      this.setorId = this.$route.params.id;
      this.buscarUltimaSequencia();
    } else this.setorId = this.$route.params.setorId;

    SetorService.buscar(this.setorId).then((response) => {
      this.setor = response.data.nome;
    });

    this.buscarPrioridade();
    this.buscaOperadorRelacional();
    this.buscarSituacoes();
  },

  methods: {
  ...mapActions(['setAbaKanban']),

  handleCampoChange() {
    this.limparCampos();
  },
  limparCampos() {
    if (this.form.campoRelacional === 'Situacao') {
      this.form.prioridade = null;
      this.form.numeroDiasParaDataEntrega = null;
    }
    if (this.form.campoRelacional === 'Prioridade') {
      this.form.situacao = null;
      this.form.numeroDiasParaDataEntrega = null;
    }
    if (this.form.campoRelacional === 'NumeroDiasDataEntrega') {
      this.form.prioridade = null;
      this.form.situacao = null;
    }
  },
  buscaOperadorRelacional() {
    EnumeradorService.buscar('EnumOperadorRelacional')
      .then((response) => {
        this.opcoes.operador = response;
      });
  },
  buscarPrioridade() {
    this.$store.dispatch('Layout/iniciarCarregamento');
    PrioridadeService.listar()
      .then((res) => {
        this.opcoes.prioridade = new DropdownModel(res.data.items);
      })
      .finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
      });
  },
  buscarSituacoes() {
    this.opcoes.situacao = helpers.SituacaoOrdemServicoEnum;
  },
  buscarUltimaSequencia() {
    if (this.id) return;
    KanbanConfiguracaoSetorGeralService.ultimaSequencia(this.$route.params.id)
      .then((response) => {
        if (response.data.items.length === 0) {
          this.form.sequencia = 1;
          return;
        }
        const objetoComMaiorSequencia = response.data.items.reduce(
          (objetoA, objetoB) => (objetoA.sequencia > objetoB.sequencia ? objetoA : objetoB)
        );
        this.form.sequencia = ++objetoComMaiorSequencia.sequencia;
      });
  },
  buscar() {
    this.$store.dispatch('Layout/iniciarCarregamento');
    KanbanConfiguracaoSetorGeralService.buscar(this.id)
      .then((response) => {
        this.form = new KanbanConfiguracaoSetorGeralModel(response.data);
        if (response.data.prioridade) {
          this.form.prioridadeId = response.data.prioridade.id;
        }
        this.valido = true;
      })
      .catch(() => {
        this.toastErro(this.$t('modulos.kanban_configuracao.erros.id_invalido'));
      })
      .finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
        this.valido = this.$refs.form.validate();
      });
  },
  confirmacaoSalvar() {
    if (!this.id) {
      this.salvar();
      return;
    }
    this.confirmarSalvar().then(() => {
      this.salvar();
    });
  },
  salvar() {
    this.form.valorRelacional = null;

    if (this.$refs.form) this.valido = this.$refs.form.validate();
    if (!this.valido) return;

    this.$store.dispatch('Layout/iniciarCarregamento');

    KanbanConfiguracaoSetorGeralService.salvar(this.form.request)
      .then(() => {
        this.setAbaKanban(1);
        this.$router.push({
          name: 'kanban-configuracao',
          params: { setorId: this.setorId },
        });
        this.toastSucesso(this.$t('modulos.kanban_configuracao.cadastro_sucesso_cartao'));
      })
      .catch((err) => {
        this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
      })
      .finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
      });
  },
  cancelar() {
    this.confirmarCancelar().then(() => {
      this.setAbaKanban(1);
      this.$router.push({
        name: 'kanban-configuracao',
        params: { setorId: this.setorId },
      });
    });
  },
}
};
</script>

<style scoped>
.p-colorpicker-preview {
  width: 40px !important;
  height: 40px !important;
}

.div-colorpicker {
  display: flex;
  padding: 0;
}
</style>
