import { BasicoApi } from '@common/api';
const KanbanConfiguracaoSetorGeralService = {
  listar: function (parametros) {
    return BasicoApi.get()('/KanbanConfiguracaoSetorGeral', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/KanbanConfiguracaoSetorGeral/${id}`);
  },
  ultimaSequencia:function(id){
    return BasicoApi.get()(`/KanbanConfiguracaoSetorGeral?SetorId=${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/KanbanConfiguracaoSetorGeral/${form.id}`, form);
    return BasicoApi.post()('/KanbanConfiguracaoSetorGeral', form);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/KanbanConfiguracaoSetorGeral/${id}`);
  },
  salvarOrdenacao: function (lista) {
    return BasicoApi.put()(`/KanbanConfiguracaoSetorGeral/atualiza-lista`, lista);
  },
};

export default KanbanConfiguracaoSetorGeralService;
