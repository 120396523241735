import cloneDeep from 'lodash/cloneDeep';
import helpers from '@common/utils/helpers';

export class KanbanConfiguracaoSetorGeralModel {
  constructor({
    id,
    setorId,
    sequencia,
    campoRelacional,
    operadorRelacional,
    valorRelacional,
    situacao,
    prioridadeId,
    prioridade,
    numeroDiasParaDataEntrega,
    corHexaFundoCartao = "f2f2f2",
    corHexaFonteCartao = "000000",
  }) {
    this.id = id;
    this.setorId = setorId;
    this.sequencia = sequencia;
    this.campoRelacional = campoRelacional;
    this.operadorRelacional = operadorRelacional;
    this.valorRelacional = valorRelacional;
    this.situacao = situacao;
    this.prioridadeId = prioridadeId;
    this.prioridade = prioridade;
    this.numeroDiasParaDataEntrega = numeroDiasParaDataEntrega;
    this.corHexaFundoCartao = corHexaFundoCartao;
    this.corHexaFonteCartao = corHexaFonteCartao;

    let situacaoEnum = helpers.SituacaoOrdemServicoEnum.find(situacao => situacao.name === this.situacao);
    if (situacaoEnum)
      this.situacao = situacaoEnum.value;
  }

  get request() {
    const retorno = cloneDeep(this);

    if (this.corHexaFundoCartao) {
      if (this.corHexaFundoCartao.includes("#")) {
        retorno.corHexaFundoCartao = this.corHexaFundoCartao;
      } else {
        retorno.corHexaFundoCartao = `#${this.corHexaFundoCartao}`;
      }
    }

    if (this.corHexaFonteCartao) {
      if (this.corHexaFonteCartao.includes("#")) {
        retorno.corHexaFonteCartao = this.corHexaFonteCartao;
      } else {
        retorno.corHexaFonteCartao = `#${this.corHexaFonteCartao}`;
      }
    }

    if (this.id) retorno.id = this.id;

    return retorno;
  }
}
